.app {
  text-align: center;
  background: url('../public/bg.svg') center;
  background-size: cover;
  width: 100%;
  min-height: 100vh;
  color: #ffffff;
}

.app h1 {
  font-family: "Berkshire Swash", serif;
  margin: 0;
  font-weight: 400;
  font-size: 4em;
}

.links-container {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  margin-top: 1.5em;
  align-items: center;
}

.links-container a {
  display: flex;
  align-items: baseline;
  font-family: 'Fira Sans Condensed', sans-serif;
  font-weight: 500;
  font-size: 2em;
  line-height: 2em;
  color: white;
  text-decoration: none;
  width: fit-content;
}

.links-container a svg {
  font-size: 0.75em;
  margin-right: 0.5em;
}
.links-container a img {
  height: 0.75em;
  margin-right: 0.5em;
}
